import {defineAsyncComponent} from 'vue';

export default defineNuxtPlugin((NuxtApp) => {
  let validationImportPromise = null;
  const importValidation = async () => {
    const { configure, defineRule, Field, Form } = await import("vee-validate");
    const { email, min } = await import("@vee-validate/rules");
    defineRule("required", (value) => {
      if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
        return false;
      }
      return !!String(value).trim().length;
    });
    defineRule("email", email);
    defineRule("min", min);
    defineRule("truthy", (value) => value == true);
    configure({
      validateOnInput: false,
      validateOnChange: false,
      validateOnBlur: false,
      validateOnModelUpdate: false,
      generateMessage: (context) => {
        console.log('GENERATE MESSAGE');
        if (context.rule.name === 'required') {
          return NuxtApp.$__("Ce champ est obligatoire");
        }
        else if (context.rule.name === 'email') {
          return NuxtApp.$__("Vérifiez le format de l'email");
        }
        else if (context.rule.name === 'truthy') {
          return NuxtApp.$__('Ce champ doit être coché');
        }
      }
    });
    return {Field, Form};
  };

  const asyncValidationField = defineAsyncComponent(async () => {
    if (!validationImportPromise) {
      validationImportPromise = importValidation();
    }
    const {Field} = await validationImportPromise;
    return Field;
  });
  const asyncValidationForm = defineAsyncComponent(async () => {
    if (!validationImportPromise) {
      validationImportPromise = importValidation();
    }
    const {Form} = await validationImportPromise;
    return Form;
  });
  NuxtApp.vueApp.component("ValidationField", asyncValidationField);
  NuxtApp.vueApp.component("ValidationForm", asyncValidationForm);
});

